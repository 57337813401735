import './App.css';
import Page from "./Page";


function App() {
  return (
      <Page/>
  );
}

export default App;
